import SourceFlowImage from "@sourceflow-uk/sourceflowimage";
import {
  Container,
  Nav,
  NavItem,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import styles from "./styles.module.scss";
import Link from "next/link";
import Logo from "/public/site-assets/svg/logo-filled-black.svg";
import MainNavigation from "../../.sourceflow/menu_navigations.json";
import SolutionsNavigation from "../../.sourceflow/solutions.json";
import PillarPages from "../../.sourceflow/pillar_pages.json";
import MobileNavigations from "../../.sourceflow/mobile_navigations.json";
import React, { useState, useEffect, useRef } from "react";
import SearchIcon from "/public/site-assets/svg/black-magnifying-glass.svg";
import CloseMark from "/public/site-assets/svg/close-mark.svg";
import Telephone from "/public/site-assets/svg/telephone.svg";
import News from "../../.sourceflow/news.json";
import DynamicPages from "../../.sourceflow/dynamic_pages.json";
import Fuse from "fuse.js";
import { useRouter } from "next/router";

import UKFlag from "/public/site-assets/svg/uk-flag.svg";
import USFlag from "/public/site-assets/svg/us-flag.svg";
import DEFlag from "/public/site-assets/svg/germany-flag.svg";
import AUFlag from "/public/site-assets/svg/au-flag.svg";


import EditableImage from "@/components/EditableImage";
import EditableText from "@/components/EditableText";
import BaseObject from '@sourceflow-uk/sourceflow-sdk/base_object';
import { useLocale } from '@/hooks/useLocale';
import NewNavigation from "../../.sourceflow/new_navigation.json";
import NewNavigationAU from "../../.sourceflow/new_navigation_au.json";
import NewNavigationUS from "../../.sourceflow/new_navigation_us.json";

import clsx from 'classnames';
import global from '@/.sourceflow/global.json';
import image from '@/.sourceflow/image_metadata.json';



export default function MainHeader({ pathPrefix, content }) {
  const router = useRouter();

  const dropdownRefs = {};
  const [isScrolled, setIsScrolled] = useState(false);

  const [isAboutDropdownOpen, setAboutIsDropdownOpen] = useState(false);
  const [isInsightsDropdownOpen, setInsightsIsDropdownOpen] = useState(false);
  const [isSolutionssDropdownOpen, setSolutionsIsDropdownOpen] =
    useState(false);
  const [isContractorsDropdownOpen, setContractorsIsDropdownOpen] =
    useState(false);
  const [isJobSeekersDropdownOpen, setJobSeekerssIsDropdownOpen] =
    useState(false);
  const [isClientsDropdownOpen, setClientsIsDropdownOpen] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };


  // Function to handle clicks outside of all dropdowns
  const handleClickOutside = (event) => {
    if (
      !event.target.closest(".dropdown-navigations") && // Check if clicked outside the dropdown-navigations
      !event.target.closest(".parent") // Check if clicked outside any dropdown button
    ) {
      setAboutIsDropdownOpen(false);
      setInsightsIsDropdownOpen(false);
      setSolutionsIsDropdownOpen(false);
      setContractorsIsDropdownOpen(false);
      setJobSeekerssIsDropdownOpen(false);
      setClientsIsDropdownOpen(false);
    }
  };

  // Function to close all dropdowns
  const closeAllDropdowns = () => {
    setAboutIsDropdownOpen(false);
    setInsightsIsDropdownOpen(false);
    setSolutionsIsDropdownOpen(false);
    setContractorsIsDropdownOpen(false);
    setJobSeekerssIsDropdownOpen(false);
    setClientsIsDropdownOpen(false);
    // Add more conditions for other dropdowns if needed
  };

  useEffect(() => {
    // Attach the event listener when any dropdown is open
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const html = document.querySelector("html");
    if (html) {
      html.style.overflow = isOpen ? "hidden" : "auto";
    }

    const handleScroll = () => {
      const currentPosition = window.scrollY;
      if (currentPosition > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isOpen]);

  const AllInsights = News.category_values.map(v => ({
    ...v, href: `insights/${v.url_slug}`
  }));

  const usInsights = AllInsights.filter((insight) =>
    Array.isArray(insight.country.en)
      ? insight.country.en.includes("United States") // Check if "United States" is in the array
      : insight.country.en === "United States" // Direct comparison if it's a string
  );
  const ukInsights = AllInsights.filter((insight) =>
    Array.isArray(insight.country.en)
      ? insight.country.en.includes("United Kingdom") // Check if "United Kingdom" is in the array
      : insight.country.en === "United Kingdom" // Direct comparison if it's a string
  );

  const AllDynamicPagesUK = DynamicPages.filter(v => !v.url_slug.startsWith('us/')).map(v => ({
    ...v, href: `${v.url_slug}`
  }));

  const AllDynamicPagesUS = DynamicPages.filter(v => v.url_slug.startsWith('us/')).map(v => ({
    ...v, href: `${v.url_slug}`
  }));

  const isUsRoute = router.asPath.startsWith("/us"); // check if url is /us
  const isUkRoute = router.asPath.startsWith("/"); // check if url is /uk

  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  // Create an instance of Fuse with your data
  const fuseOptions = {
    includeScore: true,
    keys: ["title.en", "title"],
  };

  const fuseUs = new Fuse(usInsights, fuseOptions);
  const fuseUk = new Fuse(ukInsights, fuseOptions);

  const fuseUSPages = new Fuse(AllDynamicPagesUS, fuseOptions);
  const fuseUKPages = new Fuse(AllDynamicPagesUK, fuseOptions);

  // Create a function to handle search
  const handleSearch = (query) => {
    let results;
    if (isUsRoute) {
      results = [...fuseUs.search(query), ...fuseUSPages.search(query)];
    } else {
      results = [...fuseUk.search(query), ...fuseUKPages.search(query)];
    }
    setSearchResults(results);
  };

  // Add an event handler for the search input
  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    handleSearch(query);
  };

  const [open, setOpen] = useState("");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  // ReciteMe Script
  useEffect(() => {
    const enableReciteButton = document.getElementById("reciteEnable");

    // Check if the loadService function exists
    if (typeof loadService !== "function") {
      return;
    }

    if (enableReciteButton) {
      enableReciteButton.addEventListener("click", loadService);
    }

    return () => {
      if (enableReciteButton) {
        enableReciteButton.removeEventListener("click", loadService);
      }
    };
  }, []);



  // New navigation
  const locale = useLocale();
  const main_global = new BaseObject(global, `${locale}`).toJson();
  const main_image = new BaseObject(image, `${locale}`).toJson();

  // const ParentMainNavigation = NewNavigation.category_values.filter((post) => post.parent.identifier === null);
  // const ChildrenMainNavigation = NewNavigation.category_values.filter((post) => post.parent.identifier !== null);
  let selectedNavigation;

  if (router.asPath.startsWith("/au")) {
    selectedNavigation = NewNavigationAU;
  } else if (router.asPath.startsWith("/us")) {
    selectedNavigation = NewNavigationUS;
  } else {
    selectedNavigation = NewNavigation;
  }

  // Now filter based on selectedNavigation
  const ParentMainNavigation = selectedNavigation.category_values.filter(post => post.parent.identifier === null);
  const ChildrenMainNavigation = selectedNavigation.category_values.filter(post => post.parent.identifier !== null);


  const [activeDropdown, setActiveDropdown] = useState(null);

  const newToggleDropdown = (titleEn) => {
    // Find the parent element with matching data-parent-id

    setActiveDropdown(activeDropdown === titleEn ? null : titleEn);

    const parentElement = document.querySelector(`[data-parent-id="${titleEn}"]`);

    if (parentElement) {
      const isActive = parentElement.classList.contains("active");

      // Remove 'active' class from all elements
      document.querySelectorAll("[data-parent-id]").forEach((el) => {
        el.classList.remove("active");
      });

      // Toggle the clicked element only if it wasn't active before
      if (!isActive) {
        parentElement.classList.add("active");
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the clicked element is inside any data-parent-id element
      if (!event.target.closest("[data-parent-id]") && !event.target.closest(".dropdown")) {
        setActiveDropdown(null);

        document.querySelectorAll("[data-parent-id]").forEach((el) => {
          el.classList.remove("active");
        });
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);



  const [activeParent, setActiveParent] = useState(null);

  const handleParentClick = (parentTitle) => {
    setActiveParent((prev) => (prev === parentTitle ? null : parentTitle));
  };



  let selectedInsights;

  if (router.asPath.startsWith("/au")) {
    selectedInsights = ukInsights;
  } else if (router.asPath.startsWith("/us")) {
    selectedInsights = usInsights;
  } else {
    selectedInsights = ukInsights;
  }

  const featuredPost = selectedInsights
    ?.filter((post) => post?.featured_on_navigation?.en === true)
    .slice(0, 1);


  let regionPath = "";
  if (router.asPath.startsWith("/au")) {
    regionPath = "au";
  } else if (router.asPath.startsWith("/us")) {
    regionPath = "us";
  }

  // END
  return (
    <>
      <section
        className={`${styles.root} main-navigation ${isOpen ? "active" : ""}`}
      >
        <div className="reciteWrapper py-2 bg-white">
          <Container>
            <button
              className="recite-btn p-0"
              id="reciteEnable"
              aria-label="Accessibility and Languages"
            >
              Accessibility and Languages
            </button>
          </Container>
        </div>
        <Container className="position-relative mainWrapper">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <Link
                href={`${router.asPath.startsWith("/us") ? "/us" : "/"}`}
              >
                <a className="logo me-md-4" aria-label="Lorien" title="Lorien">
                  <Logo />
                </a>
              </Link>
              <Nav className="d-none d-lg-flex">
                {ParentMainNavigation.map((post, index) => (
                  <NavItem key={index} className={`parent mx-1 m-xxxl-2 ${activeDropdown === post?.title.en ? "active" : ""}`}>
                    <button
                      className={`dropdown ${post.parent.identifier === null ? "has-children" : null
                        }`}
                      aria-label={`${post?.title.en}`}
                      onClick={() => newToggleDropdown(post?.title.en)} // Pass the dropdown name
                      ref={(ref) => (dropdownRefs[post?.title.en] = ref)} // Use a separate ref for each dropdown
                    >
                      {post?.title.en}
                    </button>
                  </NavItem>
                ))}
              </Nav>
            </div>
            <div className="d-flex align-items-center d-md-flex">
              <UncontrolledDropdown className="lang_select d-none d-lg-flex">
                <DropdownToggle aria-label="Language">
                  <span
                    className={
                      router.asPath.startsWith("/") &&
                        !router.asPath.startsWith("/us") &&
                        !router.asPath.startsWith("/au")
                        ? "active"
                        : ""
                    }
                  >
                    <UKFlag />
                  </span>
                  <span
                    className={
                      router.asPath.startsWith("/us") ? "active" : ""
                    }
                  >
                    <USFlag />
                  </span>
                  <span
                    className={
                      router.asPath.startsWith("/au") ? "active" : ""
                    }
                  >
                    <AUFlag />
                  </span>
                </DropdownToggle>

                <DropdownMenu aria-labelledby="dropdownMenuButton">
                  <DropdownItem
                    href="/"
                    title={`United Kingdom`}
                    aria-label={`United Kingdom`}
                    className={
                      router.asPath.startsWith("/") &&
                        !router.asPath.startsWith("/us") &&
                        !router.asPath.startsWith("/au")
                        ? "active"
                        : ""
                    }
                  >
                    <UKFlag />
                  </DropdownItem>
                  <DropdownItem
                    href="/us"
                    title={`United States`}
                    aria-label={`United States`}
                    className={
                      router.asPath.startsWith("/us") ? "active" : ""
                    }
                  >
                    <USFlag />
                  </DropdownItem>
                  <DropdownItem
                    href="https://www.lorienglobal.de/"
                    title={`Germany`}
                    className={
                      router.asPath.startsWith("/de") &&
                        !router.asPath.startsWith("/us")
                        ? "active"
                        : ""
                    }
                  >
                    <DEFlag />
                  </DropdownItem>
                  <DropdownItem
                    href="/au"
                    title={`Austraila`}
                    aria-label={`United Austraila`}
                    className={
                      router.asPath.startsWith("/au") ? "active" : ""
                    }
                  >
                    <AUFlag />
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <button
                className="search-icon d-flex d-none d-lg-inline-flex"
                aria-label="Search"
                onClick={toggleSearch}
              >
                <SearchIcon />
              </button>
              <Link
                href={`${router.asPath.startsWith("/us")
                  ? "/us/job-seekers#join-our-talent-network"
                  : "/job-seekers#join-our-talent-network"
                  }`}
              >
                <a className="tertiaryBtn no-arrow text-nowrap m-0 d-none d-lg-inline-block ms-2">
                  Join Our Talent Network
                </a>
              </Link>
              <Link
                href={`${router.asPath.startsWith("/us")
                  ? "/us/contact-us"
                  : "/contact-us"
                  }`}
              >
                <a
                  className="secondaryBtn no-arrow text-nowrap m-0 ms-2"
                  aria-label="Contact Us"
                >
                  <span className="telephone-icon me-lg-2">
                    <Telephone />
                  </span>
                  <span className="d-none d-lg-inline-block">Contact Us</span>
                </a>
              </Link>
              <button
                aria-label="Menu"
                className={`d-inline-block d-lg-none mobile-menu ms-2 position-relative ${isOpen ? "active" : ""
                  }`}
                onClick={() => setIsOpen((isOpen) => !isOpen)}
              >
                {!isOpen ? "Menu" : "Close"}
              </button>
            </div>
          </div>
        </Container>
        {ParentMainNavigation.length > 0 &&
          <div className="d-none d-lg-block">
            {ParentMainNavigation.map((parentPost, parentIndex) => {
              const childrenForParent = ChildrenMainNavigation.filter((childPost) => childPost.parent.id === parentPost.id);
              // Skip rendering if no children exist
              if (childrenForParent.length === 0) {
                return null; // Exit early
              }
              return (
                <div
                  className={clsx('dropdownItem')}
                  data-parent-id={parentPost.title.en}
                >
                  <div className="d-flex">
                    <div className="left-column col-4 p-4">
                      {(parentPost.title.en != "Insights") && (
                        <div>
                          <EditableImage
                            className={`mb-4 featured-image`}
                            img={{
                              path: `menu.featured_image.${parentIndex}.${regionPath}`,
                              src: `/site-assets/placeholders/img-placeholder.jpg`,
                            }}
                            size={"500x250"}
                            alt={``}
                          />
                          <EditableText
                            content={
                              <>
                                <h5>Job Seekers</h5>
                                <p>Introduction to this section. Unlock Your Potential The technology industry is constantly evolving.</p>
                              </>
                            }
                            path={`menu.featured_navigation.content.${parentIndex}.${regionPath}`}
                            global={main_global}
                            tag={`div`}
                            className={`mb-4 post-description`}
                            html={true}
                          />

                          {(parentPost.title.en === "About Us") && (
                            <Row className="mb-3 post-contact">
                              <Col md={6}>
                                <EditableText
                                  content={<a href="mailto:info@lorienglobal.com">info@lorienglobal.com</a>}
                                  path={`menu.featured_navigation.blurb.1.${parentIndex}.${regionPath}`}
                                  global={main_global}
                                  tag={`div`}
                                  className={``}
                                  html={true}
                                />
                              </Col>
                              <Col md={6}>
                                <EditableText
                                  content={<a href="tel:0207 654 1000">0207 654 1000</a>}
                                  path={`menu.featured_navigation.blurb.2.${parentIndex}.${regionPath}`}
                                  global={main_global}
                                  tag={`div`}
                                  className={``}
                                  type={"html"}
                                />
                              </Col>
                            </Row>
                          )}
                        </div>
                      )}
                      {(parentPost.title.en === "Insights" && (
                        <div>
                          <EditableText
                            content={"Latest from Lorien"}
                            path={`menu.featured_navigation.content.${parentIndex}.${regionPath}`}
                            global={main_global}
                            tag={`h5`}
                            className={`mb-3 fw-bold text-white`}
                          />
                          {featuredPost && (
                            <div>
                              <div className="featured-insights-image-wrapper position-relative">
                                <div key={featuredPost.id}>
                                  {/* <div className="post-tag gap-2 d-flex flex-wrap">
                                    {featuredPost.map((post) =>
                                      post.tag.en.map((tag, index) => (
                                        <span key={index}>{tag}</span>
                                      ))
                                    )}
                                  </div> */}
                                  {featuredPost.map((post) =>
                                    <a href={`${(regionPath && regionPath !== "au") ? `/${regionPath}` : ``}/insights/${post.url_slug}`}>
                                      <SourceFlowImage
                                        src={featuredPost.map((image) => image.image.en)}
                                        size="500x250"
                                        alt={`${post.title.en}`}
                                        className={`mb-4 featured-image w-100`}
                                      />
                                    </a>
                                  )}
                                </div>

                              </div>
                              {featuredPost.map((post) =>
                                <a href={`${(regionPath && regionPath !== "au") ? `/${regionPath}` : ``}/insights/${post.url_slug}`}>
                                  <div className="mb-4 post-description post-blog">{post.short_description.en}</div>
                                </a>
                              )}
                              {/* <EditableText
                                content={
                                  <p className="post-short-description">Introduction to this section. Unlock Your Potential The technology industry is constantly evolving.</p>
                                }
                                path={`menu.featured_navigation.content.${parentIndex}`}
                                global={main_global}
                                tag={`div`}
                                className={`mb-4`}
                                type={"html"}
                              /> */}
                            </div>
                          )}
                        </div>
                      ))}
                      {/* <a href={
                        <EditableText
                          content={"/"}
                          path={`menu.featured_navigation.button.link.${parentIndex}.${regionPath}`}
                          global={main_global}
                          tag={`span`}
                          className={``}
                        />
                      } className="primaryBtn no-arrow w-100 justify-content-center m-0">
                        <EditableText
                          content={"Discover Job Seekers Area"}
                          path={`menu.featured_navigation.button.${parentIndex}.${regionPath}`}
                          global={main_global}
                          tag={`span`}
                          className={``}
                        />
                      </a> */}
                      <EditableText
                        content={"Discover Job Seekers Area"}
                        path={`menu.featured_navigation.button.${parentIndex}.${regionPath}`}
                        global={main_global}
                        html={true}
                        tag={`div`}
                        className={`primaryBtn-wrapper m-0 w-100 justify-content-center`}
                      />
                    </div>
                    <div className='right-column p-4 w-100'>
                      <div className="wrapper">
                        {ChildrenMainNavigation.filter((childPost) => childPost.parent.id === parentPost.id).map((childPost, childpostIndex) => (
                          <div className='child-nav' key={childPost.id}>
                            <div>
                              {childPost.new_tab?.en ?
                                <a className='sub-item-parent post-title' href={childPost.url.en} target="_blank" rel="noopener noreferrer">
                                  {childPost.title.en}
                                </a>
                                :
                                childPost.url.en === "#" ?
                                  <div className="sub-item-parent no-link post-title">{childPost.title.en}</div>
                                  :
                                  <a className='sub-item-parent post-title' href={childPost.url.en}>
                                    {childPost.title.en}
                                  </a>

                              }
                              {childPost.subtitle?.en && <div className='post-subtitle mb-4 mt-2'>{childPost.subtitle.en}</div>}
                            </div>
                            {ChildrenMainNavigation.filter((sub_childPost) => sub_childPost.parent.id === childPost.id).map((sub_childPost) => (
                              <div key={sub_childPost.id}>
                                {sub_childPost.new_tab?.en ?
                                  <a className='sub-item post-title' href={sub_childPost.url.en} target="_blank" rel="noopener noreferrer">
                                    {sub_childPost.title.en}
                                  </a> :
                                  <a className='sub-item post-title' href={sub_childPost.url.en}>
                                    {sub_childPost.title.en}
                                  </a>
                                }
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        }
      </section>
      <div
        className={`${styles.root} mobile-dropdown ${isOpen ? "active" : "overflow-hidden"
          } d-inline-block d-lg-none`}
      >
        {/* <Container>
          <div className="d-flex align-items-center mb-4 mobile-search">
            <div className="w-100 position-relative filters">
              <input
                type="text"
                id="search"
                placeholder="Site Search..."
                onChange={handleSearchChange}
                value={searchQuery}
              />
              <span className="icon d-inline-block position-absolute">
                <SearchIcon />
              </span>
              {searchResults.length > 0 && (
                <div className="mt-4 search-results">
                  <p className="fs-4">
                    <strong>Search Results:</strong>
                  </p>
                  <ul>
                    {searchResults.map((post, index) => (
                      <li key={index}>
                        <Link href={post.item.href}>
                          <a onClick={() => setIsOpen((isOpen) => !isOpen)}>
                            {post.item.title.en ? post.item.title.en : post.item.title}
                          </a>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </Container> */}
        <div className="d-flex flex-wrap justify-content-between mobileWrapper">
          <div className="w-100">
            {/* MOBILE */}
            <Nav className={`d-flex flex-wrap`}>
              {!activeParent && (
                <>
                  <NavItem className={`parent w-100`}>
                    <a href="/">
                      Home
                    </a>
                  </NavItem>

                  {ParentMainNavigation.map((post, index) => (
                    <NavItem key={index} className={`parent w-100`}>
                      <button
                        className={`bg-transparent dropdown w-100 text-start ${post.parent.identifier === null ? "has-children" : null
                          }`}
                        aria-label={`${post?.title.en}`}
                        onClick={() => handleParentClick(post.title.en)}
                      >
                        <strong>{post?.title.en}</strong>
                      </button>
                    </NavItem>
                  ))}
                </>
              )}
              {activeParent && (
                <NavItem className={`parent w-100 return-btn-wrapper`}>
                  <button
                    className={`border-0 bg-transparent px-0 dropdown w-100 text-start`}
                    onClick={() => setActiveParent(null)}

                  >
                    Back
                  </button>
                </NavItem>
              )}
            </Nav>
            {ParentMainNavigation.length > 0 &&
              <div className="sub-children-wrapper">
                {ParentMainNavigation.map((parentPost, parentIndex) => {
                  const childrenForParent = ChildrenMainNavigation.filter((childPost) => childPost.parent.id === parentPost.id);
                  // Skip rendering if no children exist
                  if (childrenForParent.length === 0) {
                    return null; // Exit early
                  }
                  return (
                    <div
                      className={clsx("dropdownItem", {
                        active: activeParent === parentPost.title.en,
                      })}
                      data-mobile-parent-id={parentPost.title.en}
                    >
                      {(parentPost.title.en === "Employers" || parentPost.title.en === "Specialism" || parentPost.title.en === "Markets") && (
                        <div className="sub-children-title">{parentPost.title.en}</div>
                      )}
                      <div className="wrapper">
                        {ChildrenMainNavigation.filter((childPost) => childPost.parent.id === parentPost.id).map((childPost) => (
                          <div className='child-nav' key={childPost.id}>
                            <div>
                              {childPost.new_tab?.en ?
                                <a className='sub-item-parent post-title' href={childPost.url.en} target="_blank" rel="noopener noreferrer">
                                  {childPost.title.en}
                                </a>
                                :
                                childPost.url.en === "#" ?
                                  <div className="sub-item-parent no-link post-title">{childPost.title.en}</div>
                                  :
                                  <a className='sub-item-parent post-title' href={childPost.url.en}>
                                    {childPost.title.en}
                                  </a>
                              }
                              {ChildrenMainNavigation.filter((sub_childPost) => sub_childPost.parent.id === childPost.id).map((sub_childPost) => (
                                <div className="sub-item-child" key={sub_childPost.id}>
                                  {sub_childPost.new_tab?.en ?
                                    <a className='sub-item' href={sub_childPost.url.en} target="_blank" rel="noopener noreferrer">
                                      {sub_childPost.title.en}
                                    </a> :
                                    <a className='sub-item' href={sub_childPost.url.en}>
                                      {sub_childPost.title.en}
                                    </a>
                                  }
                                </div>
                              ))}
                            </div>
                          </div>

                        ))}
                      </div>
                    </div>
                  )
                })}
              </div >
            }
          </div >
          <div className="mobile-language w-100 d-flex">
            <div className="w-100 mb-4">
              <hr />
              <Container>
                <UncontrolledDropdown className="lang_select">
                  <DropdownToggle>
                    <span className={router.asPath === "/" ? "active" : ""}>
                      <UKFlag /> UK
                    </span>
                    <span className={router.asPath.startsWith("/us") ? "active" : ""}>
                      <USFlag /> US
                    </span>
                    <span className={router.asPath.startsWith("/au") ? "active" : ""}>
                      <AUFlag /> AU
                    </span>
                    <span className={router.asPath.startsWith("/de") ? "active" : ""}>
                      <DEFlag /> DE
                    </span>
                  </DropdownToggle>

                  <DropdownMenu aria-labelledby="dropdownMenuButton">
                    <DropdownItem
                      href="https://www.lorienglobal.com/"
                      title="United Kingdom"
                      className={router.asPath === "/" ? "active" : ""}
                    >
                      <UKFlag /> UK
                    </DropdownItem>
                    <DropdownItem
                      href="https://www.lorienglobal.com/us"
                      title="United States"
                      className={router.asPath.startsWith("/us") ? "active" : ""}
                    >
                      <USFlag /> US
                    </DropdownItem>
                    <DropdownItem
                      href="https://www.lorienglobal.com/au"
                      title="Australia"
                      className={router.asPath.startsWith("/au") ? "active" : ""}
                    >
                      <AUFlag /> AU
                    </DropdownItem>
                    <DropdownItem
                      href="https://www.lorienglobal.de/"
                      title="Germany"
                      className={router.asPath.startsWith("/de") ? "active" : ""}
                    >
                      <DEFlag /> DE
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Container>
            </div>
          </div>

        </div >
      </div >
      {isSearchOpen && (
        <section className={`${styles.root} site-search d-none d-md-block`}>
          <Container>
            <div className="d-flex align-items-center">
              <div className="w-100 position-relative filters">
                <input
                  type="text"
                  id="search"
                  placeholder="Site Search..."
                  onChange={handleSearchChange}
                  value={searchQuery}
                />
                <span className="icon d-inline-block position-absolute">
                  <SearchIcon />
                </span>
              </div>
              <button
                className={`primaryBtn no-arrow border-0 m-0 ms-3`}
                aria-label="Search"
                onClick={() => toggleSearch()}
              >
                <CloseMark />
              </button>
            </div>
            {searchResults.length > 0 && (
              <div className="mt-4 search-results">
                <p className="fs-4">
                  <strong>Search Results:</strong>
                </p>
                <ul>
                  {searchResults.map((post, index) => (
                    <li key={index}>
                      <Link
                        href={
                          isUsRoute
                            ? `/us/${post.item.href}`
                            : `/${post.item.href}`
                        }
                      >
                        <a onClick={() => toggleSearch()}>
                          {post.item.title.en ? post.item.title.en : post.item.title}
                        </a>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </Container>
        </section>
      )}
    </>
  );
}
