import SourceFlowImage from "@sourceflow-uk/sourceflowimage"
import {
    Container,
    Row,
    Col,
    Card,
    CardGroup,
    Nav,
    NavItem,
    CardBody
} from 'reactstrap';
import styles from './styles.module.scss';
import Link from "next/link"
import Logo from "/public/site-assets/svg/logo-black.svg"
import ImpellamGroup from "/public/site-assets/svg/impellam-group.svg"
import React, {
    useRef,
    useEffect,
    useState
} from "react";
import { useRouter } from 'next/router';
import Sourceflow from '/public/sourceflow_logo.svg';
import SocialLinks from '/components/SocialLinks';
import FooterCopyrightNavigations from '../../.sourceflow/footer_copyright_navigations.json';
import FooterAboutLorienNavigations from '../../.sourceflow/footer_about_lorien_navigations.json';
import FooterJobSeekersNavigations from '../../.sourceflow/footer_job_seekers_navigations.json';
import FooterDocumentsNavigations from '../../.sourceflow/footer_documents_navigations.json';
import FooterSolutionsnNavigations from '../../.sourceflow/solutions.json';
import NewNavigation from "../../.sourceflow/new_navigation.json";
import NewNavigationAU from "../../.sourceflow/new_navigation_au.json";
import NewNavigationUS from "../../.sourceflow/new_navigation_us.json";

export default function MainFooter({
    global,
    pathPrefix,
    customClass = "",
}) {
    const router = useRouter();

    let selectedNavigation;

    if (router.asPath.startsWith("/au")) {
        selectedNavigation = NewNavigationAU;
    } else if (router.asPath.startsWith("/us")) {
        selectedNavigation = NewNavigationUS;
    } else {
        selectedNavigation = NewNavigation;
    }

    // Now filter based on selectedNavigation
    const ParentMainNavigation = selectedNavigation.category_values.filter(post => post.parent.identifier === null);
    const ChildrenMainNavigation = selectedNavigation.category_values.filter(post => post.parent.identifier !== null);

    return (
        <section className={`${styles.root} ${customClass} position-relative`}>
            <Container>
                <hr></hr>
                <div className="pt-3">
                    <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center">
                        <Link href="/">
                            <a aria-label="Logo" title="Lorien" className="d-inline-block mb-4 mb-md-0">
                                <Logo />
                            </a>
                        </Link>
                        <div>
                            <strong>Get in Touch</strong>
                            <div className="d-flex flex-column flex-md-row align-items-md-center gap-2 gap-md-5">
                                <div className="d-flex align-items-center gap-3">
                                    {(router.asPath.startsWith("/us") || router.asPath.startsWith("/au")) &&
                                        <p className="mb-0">
                                            <Link href={router.asPath.startsWith("/us") ? "tel:+1954.433.4100" : router.asPath.startsWith("/au") ? "tel:1800314783" : ""}>
                                                <a>
                                                    {router.asPath.startsWith("/us") ? "+1 954.433.4100" : router.asPath.startsWith("/au") ? "1800 314 783" : ""}
                                                </a>
                                            </Link>
                                        </p>
                                    }
                                    <p className="mb-0">
                                        <Link href={router.asPath.startsWith("/us") ? "mailto:connect@lorienglobal.com" : router.asPath.startsWith("/au") ? "mailto:contractorsapac@lorienglobal.com" : "mailto:info@lorienglobal.com"}>
                                            <a>
                                                {router.asPath.startsWith("/us") ? "connect@lorienglobal.com" : router.asPath.startsWith("/au") ? "contractorsapac@lorienglobal.com" : "info@lorienglobal.com"}
                                            </a>
                                        </Link>
                                    </p>
                                </div>
                                <SocialLinks />
                            </div>
                        </div>
                    </div>
                    <Row className="py-4">
                        <Col md={12}>
                            <Row className="justify-content-between">
                                {ParentMainNavigation.map((post) => (
                                    <Col md={6} lg={2} key={post.id}>
                                        <p className="mb-1">{post?.title.en}</p>
                                        <ul className="list-unstyled navigations">
                                            {ChildrenMainNavigation.filter((childPost) => childPost.parent.id === post.id).map((childPost) => (
                                                <>
                                                    {childPost.url.en === "#" ?
                                                        ChildrenMainNavigation.filter((sub_childPost) => sub_childPost.parent.id === childPost.id).map((sub_childPost) => (
                                                            <li key={sub_childPost.id}>
                                                                <Link href={sub_childPost.url.en} key={sub_childPost.id}>
                                                                    <a className="text-decoration-none">
                                                                        {sub_childPost.title.en}
                                                                    </a>
                                                                </Link>
                                                            </li>
                                                        ))
                                                    :
                                                        <li key={childPost.id}>
                                                            <Link href={childPost.url.en}>
                                                                <a className="text-decoration-none">
                                                                {childPost.title.en}
                                                                </a>
                                                            </Link>
                                                        </li>
                                                    }
                                                </>
                                            ))}
                                        </ul>
                                    </Col>
                                ))}
                                <Col md={6} lg={2}>
                                    <p className="mb-1">Documents</p>
                                    <ul className="list-unstyled navigations">
                                        {FooterDocumentsNavigations.category_values.map((post, index) => (
                                            <li key={index}>
                                                {post?.new_tab ?
                                                    <Link href={`${post.link.en}`}>
                                                        <a className="text-decoration-none" target="_blank" rel="noopener noreferrer">
                                                            {post.title.en}
                                                        </a>
                                                    </Link> :
                                                    <Link href={`${router.asPath.startsWith("/us") ? "/us" : ""}${post.link.en}`}>
                                                        <a className="text-decoration-none">
                                                            {post.title.en}
                                                        </a>
                                                    </Link>}
                                            </li>
                                        ))}
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-start my-4">
                    <span className="impellem-group d-flex align-items-end me-4">
                        <small>Part Of</small>
                        <Link href="https://www.impellam.com/">
                            <a target="_blank" rel="noopener noreferrer" title="ImpellAm Group" className="ms-1">
                                <ImpellamGroup /></a></Link>
                    </span>
                    <div className="my-4 my-md-0">
                    <small className="d-flex justify-content-center">© Lorien, Registered Address: First Floor, Mulberry House, Parkland Square, 750 Capability Green, Luton, LU1 3LU</small>
                        <ul className="d-flex flex-wrap list-unstyled footer-navigations-2" >
                            {FooterCopyrightNavigations.category_values.map((post, index) => (
                                <li className="me-md-3 mb-1 pe-2 pe-md-0" key={index}>
                                    {post.new_tab ?
                                        <Link href={`${post.link.en}`}>
                                            <a className="text-decoration-none" target="_blank" rel="noopener noreferrer">
                                                <small>{post.title.en}</small>
                                            </a>
                                        </Link> :
                                        <Link href={`${router.asPath.startsWith("/us") ? "/us" : ""}${post.link.en}`}>
                                            <a className="text-decoration-none">
                                                <small>{post.title.en}</small>
                                            </a>
                                        </Link>}

                                </li>
                            ))}
                        </ul>
                    </div>
                
                    <div className="text-nowrap d-flex align-items-end">
                        <p className="mb-0 me-3"><small>© {new Date().getFullYear()} Lorien.</small></p>
                        <p className="mb-0 me-1"><small>Site by</small></p>
                        <Link href="https://www.sourceflow.co.uk/">
                            <a className="sourceflow" rel="noopener noreferrer" target="_blank" title="SourceFlow">
                                <Sourceflow />
                            </a>
                        </Link>
                    </div>
                </div>
            </Container>
        </section>
    )
}